$( document ).ready(function() {
	var body = document.querySelector("body");
    $("#dropDownInsuranceServices").hover(function(){
    	if($(window).width() > 1200){
    		// $("#dropDownInsuranceServicesMenu").addClass("show");	
    		$("#new-menu-nim").addClass("show-menu");	
    		// $("#dropDownInsuranceServicesMenu").addClass("d-none");	
    	}
	},function(){
		if($(window).width() > 1200){
    		$("#new-menu-nim").removeClass("show-menu");
    		// $("#dropDownInsuranceServicesMenu").removeClass("d-none");
    	}		
	});
	$('.content-btn-close').click(function(){
		$('.menu-mobile-version').removeClass('active');
		// $('#btn-close').addClass('d-none');
		// $('#btn-burger').removeClass('d-none');
		$('body').css({'overflow':'visible'});
	})
	$('#btn-show-mobile-nav').click(function(){
		$('.menu-mobile-version').addClass('active');
		// $('#btn-burger').addClass('d-none');
		// $('#btn-close').removeClass('d-none');
		$('body').css({'overflow':'hidden'});
	})
});